@if (emproSpinnerService.loading(); as loader) {
  @if (loader.showSpinner) {
    <empro-spinner
      [show]="loader.show"
      [duration]="loader.duration"
      [showOverlay]="loader.showOverlay"
      [size]="loader.size"
      [variant]="loader.variant"
    />
  }
}

<router-outlet />
